<template>
  <div>
    <span
      v-if="record.deleted"
      class="btn-cursor"
      @click="assignDataDeleted"
    >
      {{ $t('Details') }}
    </span>
    <span
      v-else
      class="btn-cursor"
      @click="fetchDetail"
    >
      {{ $t('Details') }}
    </span>

    <b-modal
      id="logModal"
      v-model="showDetail"
      centered
      ok-only
      :title="$t('Details')"
      no-close-on-backdrop
      @close="closeDetail"
    >
      <template v-if="!more_detail">
        <b-col v-if="records && !records.deleted">
          <div
            v-for="(changeValue, field, index) in records"
            :key="field+String(index)"
          >
            <span v-if="field != 'hash' && changeValue[0] != null">
              <b-row>
                <b-col cols="5">
                  {{ $t(`zzFields.${field}`) }}:
                </b-col>
                <b-col cols="7">
                  <strong v-if="typeof changeValue[0] == 'string' && !isNaN(Date.parse(changeValue[0]))">
                    {{ changeValue[0] | formatDateTime }}
                  </strong>
                  <strong v-else-if="typeof changeValue[0] == 'string' && isNaN(Date.parse(changeValue[0]))">
                    {{ changeValue[0] }}
                  </strong>
                  <strong v-else-if="typeof changeValue[0] == 'boolean' && field == 'status'">
                    {{ changeValue[0] ? $t('generic.active') : $t('generic.inactive') }}
                  </strong>
                  <strong v-else-if="typeof changeValue[0] == 'boolean'">
                    {{ $t(`generic.${changeValue[0]}`) }}
                  </strong>
                  <strong v-else-if="changeValue[1] != null">
                    <span
                      class="btn-cursor text-primary"
                      @click="fetchRelationDetail(changeValue)"
                    >
                      {{ (changeValue[0] != 0) ? $t('Details') : null }}
                    </span>
                  </strong>
                  <strong v-else>
                    {{ changeValue[0] }}
                  </strong>
                </b-col>
              </b-row>
            </span>
          </div>
        </b-col>
        <b-col v-else-if="records && records.deleted">
          <div
            v-for="(changeValue, field, index) in records.changes_dec"
            :key="field+String(index)"
          >
            <span v-if="field != 'hash' && changeValue != null && field.slice(-5) != '_hash'">
              <b-row>
                <b-col cols="5">
                  {{ $t(`zzFields.${field}`) }}:
                </b-col>
                <b-col cols="7">
                  <strong v-if="typeof changeValue == 'string' && !isNaN(Date.parse(changeValue))">
                    {{ changeValue | formatDateTime }}
                  </strong>
                  <strong v-else-if="typeof changeValue == 'string' && isNaN(Date.parse(changeValue))">
                    {{ changeValue }}
                  </strong>
                  <strong v-else-if="typeof changeValue == 'boolean' && field == 'status'">
                    {{ changeValue ? $t('generic.active') : $t('generic.inactive') }}
                  </strong>
                  <strong v-else-if="typeof changeValue == 'boolean'">
                    {{ $t(`generic.${changeValue}`) }}
                  </strong>
                  <strong v-else>
                    {{ changeValue }}
                  </strong>
                </b-col>
              </b-row>
            </span>
          </div>
        </b-col>
      </template>
      <template v-else>
        <b-col v-if="records">
          <div
            v-for="(rDetail, rField, rIndex) in relation_detail"
            :key="rField+String(rIndex)+rField"
          >
            <span v-if="rField != 'hash' && rDetail[0] != null">
              <b-row>
                <b-col cols="5">
                  {{ $t(`zzFields.${rField}`) }}:
                </b-col>
                <b-col cols="7">
                  <strong v-if="typeof rDetail[0] == 'string' && !isNaN(Date.parse(rDetail[0]))">
                    {{ rDetail[0] | formatDateTime }}
                  </strong>
                  <strong v-else-if="typeof rDetail[0] == 'string' && isNaN(Date.parse(rDetail[0]))">
                    {{ rDetail[0] }}
                  </strong>
                  <strong v-else-if="typeof rDetail[0] == 'boolean' && rField == 'status'">
                    {{ rDetail[0] ? $t('generic.active') : $t('generic.inactive') }}
                  </strong>
                  <strong v-else-if="typeof rDetail[0] == 'boolean'">
                    {{ $t(`generic.${rDetail[0]}`) }}
                  </strong>
                  <strong v-else>
                    {{ rDetail[0] }}
                  </strong>
                </b-col>
              </b-row>
            </span>
          </div>
        </b-col>
      </template>
      <template #modal-footer>
        <div class="btn-footer-modal">
          <b-button
            v-if="more_detail"
            variant="secondary"
            pill
            style="margin-left: 17px;"
            @click="more_detail = false"
          >
            {{ $t('buttons.back') }}
          </b-button>
          <b-button
            variant="primary"
            pill
            style="margin-left: 17px;"
            @click="closeDetail"
          >
            {{ $t('generic.accept') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import logService from '@/services/logService'

export default {
  props: {
    record: {
      type: Object,
      required: true,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showDetail: false,
      more_detail: false,
      relation_detail: {},
      records: {},
      filters: {
        page: 1,
        perPage: 15,
      },
      details: [],
    }
  },
  methods: {
    assignDataDeleted() {
      this.records = this.record
      this.more_detail = false
      this.showDetail = true
    },
    fetchDetail() {
      const dataForm = { model_description_hash: this.record.model_description_hash }

      logService.getLogInfo(this.record.record_hash, dataForm)
        .then(({ data }) => {
          this.records = data.data
          this.more_detail = false
          this.showDetail = true
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    closeDetail() {
      this.showDetail = false
    },
    fetchRelationDetail(params) {
      const dataForm = { table: params[1] }

      logService.getRelationDetail(params[0], dataForm)
        .then(({ data }) => {
          this.relation_detail = data.data
          this.more_detail = true
        }).catch(error => {
          this.responseCatch(error)
        })
    },
  },
}
</script>
<style scoped lang="scss">
.btn-cursor {
  cursor: pointer;
}
.btn-footer-modal {
  float: right
}
@media (max-width: 450px) {
  .btn-footer-modal {
    float: none !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
